import {
    Badge,
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    HStack, Input, SimpleGrid,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList
} from '@choc-ui/chakra-autocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormLabel } from "../../components";
import ChakraModal from "../../components/chakra-modal/ChakraModal";
import ChakraTable from "../../components/chakra-table/ChakraTable";
import PageHeader from "../../components/page-header/PageHeader";
import useInput from "../../hooks/use-input.hook";
import { useExecMethodApiPostMutation, useExecMethodApiQuery } from "../../services/erp-method-api.service";
import { useGetQuery, useUpdateMutation } from "../../services/erp-resource-api.service";
import QRCode from "react-qr-code";

export const PalletViewPage = () => {

    const [isAddOpen, setIsAddOpen] = useState<any>(false)
    const [isQrOpen, setIsQrOpen] = useState<any>(false)
    const [batchList, setBatchList] = useState<any>(null)
    const [selectedBatch, setSelectedBatch] = useState<any>(null)
    const [form, handleOnChange, handleOnChangeForValue, clearAllFields] = useInput();
    const toast = useToast()
    const { id, palletId } = useParams()

    const { data: orderLines } = useExecMethodApiQuery({
        method: 'get_po_item_lines',
        body: {
            docEntry: 206
        }
    })

    const { data: pallet } = useGetQuery({
        doc: 'JB Pallet',
        name: palletId
    })

    const [getBatches] = useExecMethodApiPostMutation();
    const [updatePallet, { isLoading, isError }] = useUpdateMutation();

    useEffect(() => {
        clearAllFields()
        console.log("adada");
        
    }, [isAddOpen])

    let palletLines = _.get(pallet, 'data.lines')

    const onItemSelectHandler = async (value: any, line: any) => {
        const { item_code, name, item_description, line_num } = _.get(line, 'originalValue')
        const response = await getBatches({
            method: 'get_po_batch_breakdowns',
            payload: {
                name,
                docEntry: id
            }
        }).unwrap();

        handleOnChangeForValue('line_num', line_num)
        handleOnChangeForValue('item_name', item_description)
        handleOnChangeForValue('item_code', item_code)

        setSelectedBatch(null)
        setBatchList(_.get(response, 'message'))
    }

    const onSelectedBatchHandler = (value: any, line: any) => {
        const parsedLine = JSON.parse(line.originalValue)

        handleOnChangeForValue('batch_num', _.get(parsedLine, 'data.batch_number'))
        setSelectedBatch({
            batchNumber: _.get(parsedLine, 'data.batch_number'),
            bestBefore: _.get(parsedLine, 'data.best_before'),
            mcCount: _.get(parsedLine, 'data.mc_count'),
            quantity: _.get(parsedLine, 'data.quantity'),
            remainingMcCount: _.get(parsedLine, 'remaining_mc_count')
        })
    }

    const createPalletHandler = async () => {
        const res = await updatePallet({
            doc: 'JB Pallet',
            name: palletId,
            payload: {
                lines: [form, ...palletLines]
            }
        })

        clearAllFields()
        setIsAddOpen(false)

        if (isError) {
            toast({
                title: 'Error!',
                description: "Something went wrong!",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
            setIsAddOpen(false)
        } else {
            setIsAddOpen(false)
        }
    }

    const COLUMN = [
        {
            header: "Line Number",
            accessor: "line_num",
        },
        {
            header: "Item Id",
            accessor: "item_code",
        },
        {
            header: "Item Name",
            accessor: "item_name"
        },
        {
            header: "Batch Number",
            accessor: "batch_num",
        },
        {
            header: "MC Count",
            accessor: "quantity"
        },
        {
            header: "Actions",
            options: [
                {
                    label: "Delete",
                    action: async (rowLine: any) => {
                        const resArr = palletLines.filter((item: any) => item.name != rowLine.name)

                        await updatePallet({
                            doc: 'JB Pallet',
                            name: palletId,
                            payload: {
                                lines: resArr
                            }
                        })
                        clearAllFields()

                    }
                }
            ]
        }
    ]

    console.log({ form });


    return (
        <>
            <ChakraModal size={'xl'} isLoading={isLoading} primaryAction={createPalletHandler} modalTitle={"Create Pallet"} isOpen={isAddOpen} onClose={setIsAddOpen}>
                <VStack spacing={2}>
                    <FormControl>
                        <FormLabel>Item</FormLabel>

                        <AutoComplete onChange={onItemSelectHandler} rollNavigation>
                            <AutoCompleteInput size={'sm'} placeholder="Search..." autoFocus />
                            <AutoCompleteList>
                                {(_.get(orderLines, 'message.items') ?? []).map((line: any) => (
                                    <AutoCompleteItem
                                        key={line.name}
                                        label={`${line.item_description} `}
                                        value={line}
                                        textTransform="capitalize"
                                        align="center"
                                    >
                                        <Text fontSize={12} ml="4">{line.item_description}</Text>
                                    </AutoCompleteItem>
                                ))}
                            </AutoCompleteList>
                        </AutoComplete>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Batch</FormLabel>

                        <AutoComplete onChange={onSelectedBatchHandler} rollNavigation>
                            <AutoCompleteInput size={'sm'} placeholder="Search..." />
                            <AutoCompleteList>
                                {batchList?.map((line: any) => (
                                    <AutoCompleteItem
                                        key={line.data.name}
                                        label={`${line.data.batch_number} - ${line.data.best_before} `}
                                        value={JSON.stringify(line)}
                                        textTransform="capitalize"
                                        align="center"
                                    >
                                        <HStack w={'100%'} pr={4} justifyContent={'space-between'}>
                                            <Text fontSize={12} mx="4">{`${line.data.batch_number} -${line.data.best_before}`}</Text>
                                            <Badge ms={4} colorScheme={line.isFulfilled ? 'green' : 'orange'}>{line.isFulfilled ? 'Fullfilled' : 'Not Fullfilled'}</Badge>
                                        </HStack>
                                    </AutoCompleteItem>
                                ))}
                            </AutoCompleteList>
                        </AutoComplete>
                    </FormControl>

                    {selectedBatch && (
                        <VStack style={{ marginTop: '17px' }} w={'full'} spacing={2} alignItems={'start'} p={3} borderRadius={6} bg={'blackAlpha.200'}>
                            <Heading size={"xs"}>Batch Details</Heading>
                            <Box w={'full'}>
                                <Flex w={'full'}>
                                    <Text fontSize={13} flex={0.5}>Batch Number</Text>
                                    <Text fontSize={13} flex={0.5}>{_.get(selectedBatch, 'batchNumber')}</Text>
                                </Flex>

                                <Flex w={'full'}>
                                    <Text fontSize={13} flex={0.5}>Best Before</Text>
                                    <Text fontSize={13} flex={0.5}>{_.get(selectedBatch, 'bestBefore')}</Text>
                                </Flex>

                                <Flex w={'full'}>
                                    <Text fontSize={13} flex={0.5}>Quantity</Text>
                                    <Text fontSize={13} flex={0.5}>{_.get(selectedBatch, 'quantity')}</Text>
                                </Flex>

                                <Flex w={'full'}>
                                    <Text fontSize={13} flex={0.5}>Master Cartons Count</Text>
                                    <Text fontSize={13} flex={0.5}>{_.get(selectedBatch, 'mcCount')}</Text>
                                </Flex>
                            </Box>
                        </VStack>
                    )}

                    {selectedBatch && (
                        <FormControl isInvalid={_.get(selectedBatch, 'remainingMcCount') < form['quantity']}>
                            <FormLabel>MC Count</FormLabel>
                            <Input placeholder={`Max mc count ${_.get(selectedBatch, 'remainingMcCount')}`} disabled={selectedBatch.remainingMcCount === 0} required name="quantity" onChange={handleOnChange} type={'number'} size={'sm'} />
                            {_.get(selectedBatch, 'remainingMcCount') < form['quantity'] && <FormErrorMessage mt={0}>Quantity exceeded maximum </FormErrorMessage>}
                        </FormControl>
                    )}
                </VStack>
            </ChakraModal>

            <ChakraModal size={'sm'} primaryButtonText={'Print'} modalTitle={palletId?.toString() ?? ''} isOpen={isQrOpen} onClose={setIsQrOpen}>

                <QRCode
                    style={{ margin: 'auto' }}
                    value={palletId?.toString() ?? ''}
                />

            </ChakraModal>

            <PageHeader title={palletId} subTitle={`PO Id: ${id}`} onPress={() => {
                setSelectedBatch(null)
                setIsAddOpen(true)
            }}
                buttonText={"Add Pallet Details"} />

            <SimpleGrid columns={2} gap={4}>
                <Box pb={5}>
                    <VStack spacing={2} alignItems={'start'} p={4} borderRadius={6} bg={'white'}>
                        <Heading size={"sm"}>Pallet Details</Heading>
                        <Box w={'full'}>
                            <Flex w={'full'}>
                                <Text fontSize={13} flex={0.5}>Pallet Id</Text>
                                <Text fontSize={13} flex={0.5}>{palletId}</Text>
                            </Flex>

                            <Flex w={'full'}>
                                <Text fontSize={13} flex={0.5}>PO Id</Text>
                                <Text fontSize={13} flex={0.5}>{id}</Text>
                            </Flex>
                        </Box>
                    </VStack>
                </Box>

                <HStack alignItems={'flex-start'}>
                    <Button size={"xs"} colorScheme={'blue'}>Print</Button>
                    <Button onClick={() => setIsQrOpen(true)} size={"xs"} colorScheme={'blue'}>Generate QR Code</Button>
                </HStack>
            </SimpleGrid>

            <ChakraTable title={'Pallet Breakdown'} onSelected={(line: any) => {
            }} columns={COLUMN} data={palletLines ?? []} />
        </>
    )
}