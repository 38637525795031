import {Outlet} from "react-router";

const ExternalLayout = () => {

    return (
        <>
            <Outlet/>
        </>
    );
}
export default ExternalLayout