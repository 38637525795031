import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/root.reducer";
import { erpResourceApi } from "../services/erp-resource-api.service";
import { erpMethodApi } from "../services/erp-method-api.service";
import usersApi from "../services/erp-users.service";

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false
        })
            .concat(erpResourceApi.middleware)
            .concat(erpMethodApi.middleware)
            .concat(usersApi.middleware)
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootStore = ReturnType<typeof store.getState>;
