import { Box, Heading, HStack, IconButton, Input, Stack, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import React from 'react';
import TableTr from "./components/TableTr";
import { HiPlusCircle } from "react-icons/hi";

interface INormal {
    header: string | number,
    accessor: string | number
}

interface conditionMap {
    [key: string | number]: string | number
}


interface IMedium {
    header: string | number,
    accessor: string | number,
    conditions: conditionMap
}

interface IActionProps {
    label: string | number,
    action: (line: { [key: string | number]: string | number }) => void
}

interface IActions {
    header: string | number,
    options: Array<IActionProps>
}

interface IProps {
    title?: string,
    primaryAction?: any,
    searchable?: boolean,
    columns: Array<INormal | IMedium | IActions>,
    data: Array<{ [key: string | number]: string | number }>,
    onSelected: (line: { [key: string | number]: string | number }) => void
}


const ChakraTable = ({ columns, data, onSelected, title, primaryAction, searchable = true }: IProps) => {

    function selectedRow(row: any) {
        onSelected(row)
    }

    return (
        <Stack bg={"white"} borderRadius={"md"}>
            <HStack p={4} justifyContent={"space-between"}>
                <Box>
                    <Heading size={"sm"}>
                        {title}
                    </Heading>

                    {searchable && <Input mt={2} size={"sm"} w={280} type='tel' placeholder='Search' />}
                </Box>

                {
                    primaryAction ? <IconButton onClick={primaryAction} size={"sm"} aria-label='Search database'
                        icon={<HiPlusCircle />} /> : null
                }

            </HStack>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        {columns?.map((item: any, index: any) => <Th
                            textAlign={item?.header == "Actions" ? "center" : "left"} pb={2} pt={0}
                            key={index}>{item.header}</Th>)}
                    </Tr>
                </Thead>
                <Tbody>
                    {data?.map((item: any, index: any) => <TableTr onSelected={(selectedItem: any) => {
                        selectedRow(selectedItem)
                    }} key={index} columns={columns} item={item} />)}
                </Tbody>
            </Table>
        </Stack>
    )
}

export default ChakraTable