import { Box, Drawer, DrawerContent, useDisclosure } from "@chakra-ui/react";
import { Outlet } from "react-router";
import MobileNav from "../../components/mobile-nav/MobileNav";
import SidebarContent from "../../components/side-bar-content/SideBarContent";


const InternalLayout = () => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box minH="100vh" bg={'gray.100'}>
            {/* <SidebarContent
                onClose={() => onClose}
                display={{base: 'none', md: 'block'}}
            /> */}
            {/* <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose}/>
                </DrawerContent>
            </Drawer> */}

            <MobileNav onOpen={onOpen} />

            <Box p="4">
                <Outlet />
            </Box>
        </Box>
    )
}
export default InternalLayout