import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";

interface IProps {
    modalTitle: string,
    isOpen: boolean,
    onClose: any,
    children: any,
    primaryAction?: any,
    primaryButtonText?: string
    secondarayAction?: any,
    form?: string,
    size?: string,
    primaryActionButtonType?: 'button' | 'submit' | 'reset',
    isLoading?: boolean
}

const ChakraModal = ({
    isOpen = false,
    modalTitle = "Modal Title",
    onClose,
    children,
    form,
    primaryActionButtonType = 'submit',
    primaryButtonText = "Create",
    primaryAction,
    size = 'md',
    secondarayAction = () => { },
    isLoading = false
}: IProps) => {

    return (
        <>
            <Modal
                closeOnOverlayClick={!isLoading}
                isOpen={isOpen}
                motionPreset='slideInBottom'
                onClose={onClose}
                size={size}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{modalTitle}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        {children}
                    </ModalBody>

                    <ModalFooter>
                        <Button size={"sm"} disabled={isLoading} isLoading={isLoading} type={primaryActionButtonType}
                            form={form}
                            onClick={primaryAction} colorScheme='blue' mr={3}>
                            {primaryButtonText}
                        </Button>
                        <Button size={"sm"} onClick={() => {
                            secondarayAction()
                            onClose(false)
                        }}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ChakraModal