import {combineReducers} from "redux";
import {erpResourceApi} from "../../services/erp-resource-api.service";
import {erpMethodApi} from "../../services/erp-method-api.service";
import usersApi from "../../services/erp-users.service";
import _localStore from './local-store/local.store'


const rootReducer = combineReducers({
    localStore: _localStore,
    [erpResourceApi.reducerPath]: erpResourceApi.reducer,
    [erpMethodApi.reducerPath]: erpMethodApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
})

export default rootReducer;
