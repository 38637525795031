import { Box } from "@chakra-ui/react";
import React from 'react';
import PageHeader from "../../components/page-header/PageHeader";
import ChakraTable from "../../components/chakra-table/ChakraTable";
import { useNavigate } from "react-router-dom";
import { useListQuery } from "../../services/erp-resource-api.service";
import { useUserStateQuery } from "../../services/erp-users.service";
import _ from "lodash";
import { InnerLoading } from "../../components";

const DashboardPage = () => {

    const navigate = useNavigate()
    const { data, isLoading } = useListQuery({
        method: 'JB Purchase Order',
        fields: ['doc_entry', 'doc_date', 'supplier', 'doc_status', 'doc_total']
    })

    const COLUMN = [
        {
            header: "PO Number",
            accessor: "doc_entry",
        },
        {
            header: "Date",
            accessor: "doc_date",
        },
        {
            header: "Supplier",
            accessor: "supplier",
        },
        {
            header: "Status",
            accessor: "doc_status",
        }
    ]

    return (
        <Box as="section" py={{ base: '4', md: '0' }}>
            <PageHeader title={"Purchase Orders"} subTitle={"Purchase Orders List"} primaryButton={false} />

            <ChakraTable onSelected={(line: any) => navigate(`po/${line.doc_entry}`)} columns={COLUMN} data={_.get(data, 'data') ?? []} />

            {isLoading && <InnerLoading />}
        </Box>
    )
}

export default DashboardPage