import { Box, Flex, FormControl, FormErrorMessage, Heading, Input, SimpleGrid, Text, useToast, VStack } from "@chakra-ui/react";
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { FormLabel } from "../../components";
import ChakraModal from "../../components/chakra-modal/ChakraModal";
import ChakraTable from "../../components/chakra-table/ChakraTable";
import PageHeader from "../../components/page-header/PageHeader";
import useInput from "../../hooks/use-input.hook";
import { useGetQuery, useUpdateMutation } from "../../services/erp-resource-api.service";
import { generalSchema } from "./schemas/create-batch.schema";

export const ItemViewPage = () => {

    const [form, handleOnChange, handleOnChangeForValue, clearAllFields] = useInput();
    const [isAddOpen, setIsAddOpen] = useState<any>(false)
    const [isQrGeneratorOpen, setQrGeneratorOpen] = useState<any>(false)
    const [selectedBatch, setSelectedbatch] = useState<any>(null)
    const { id, name } = useParams()
    const toast = useToast()

    useEffect(() => {
        clearAllFields()
    }, [isAddOpen])

    const { data } = useGetQuery({
        doc: 'JB Purchase Order Line',
        name
    })

    const [createBatch, { isLoading, isError }] = useUpdateMutation();

    let line = _.get(data, 'data')

    const createBatchHandler = async () => {
        const isValid = await generalSchema.isValid(form)
        let batchBreakdowns = _.get(line, 'batch_breakdown')

        if (isValid) {
            await createBatch({
                doc: 'JB Purchase Order Line',
                name,
                payload: {
                    batch_breakdown: [form, ...batchBreakdowns]
                }
            }).unwrap();

            if (isError) {
                toast({
                    title: 'Error!',
                    description: "Something went wrong!",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
                setIsAddOpen(false)
            } else {
                setIsAddOpen(false)
                clearAllFields()
            }

        } else {
            toast({
                title: 'Error!',
                description: "Please fill all required fields!",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }
    }

    const getRemainingQty = (line: any) => {
        if (!_.isEmpty(_.get(line, 'batch_breakdown'))) {
            const qty = _.get(line, 'batch_breakdown').reduce((acc: any, curr: any) => {
                return acc + +curr.quantity
            }, 0)

            return _.get(line, 'quantity') - qty

        } else {
            return _.get(line, 'quantity')
        }
    }

    const COLUMN = [
        {
            header: "Batch Number",
            accessor: "batch_number",
        },
        {
            header: "Best Before",
            accessor: "best_before"
        },
        {
            header: "MC Count",
            accessor: "mc_count"
        },
        {
            header: "Quantity",
            accessor: "quantity"
        },
        {
            header: "Actions",
            options: [
                {
                    label: "Delete",
                    action: async (rowLine: any) => {
                        const resArr = _.get(line, 'batch_breakdown').filter((item: any) => item.name != rowLine.name)

                        await createBatch({
                            doc: 'JB Purchase Order Line',
                            name,
                            payload: {
                                batch_breakdown: resArr
                            }
                        })

                    }
                },
                {
                    label: 'Generate QR Codes',
                    action: (rowLine: any) => {
                        setSelectedbatch(rowLine)
                        setQrGeneratorOpen(true)

                        console.log(rowLine);

                    }
                }
            ]
        }
    ]

    return (
        <>
            <ChakraModal isLoading={isLoading} size={'sm'} primaryAction={createBatchHandler} modalTitle={"Create Batches"} isOpen={isAddOpen} onClose={setIsAddOpen}>

                <SimpleGrid columns={1} gap={4}>
                    <VStack spacing={2}>
                        <FormControl>
                            <FormLabel>Batch Number</FormLabel>
                            <Input required name="batch_number" onChange={handleOnChange} size={'sm'} />
                        </FormControl>

                        <FormControl>
                            <FormLabel>Best Before</FormLabel>
                            <Input required name="best_before" onChange={handleOnChange} type={'date'} size={'sm'} />
                        </FormControl>

                        <FormControl isInvalid={form['quantity'] > getRemainingQty(line)}>
                            <FormLabel>Quantity (Units)</FormLabel>
                            <Input
                                type={'number'}
                                required
                                name="quantity"
                                placeholder={`Max quantity ${getRemainingQty(line)}`}
                                max={getRemainingQty(line)}
                                onChange={handleOnChange}
                                size={'sm'} />
                            {form['quantity'] > getRemainingQty(line) && <FormErrorMessage mt={0}>Quantity exceeded maximum </FormErrorMessage>}
                        </FormControl>

                        <FormControl>
                            <FormLabel>Quantity (MC)</FormLabel>
                            <Input type={'number'} required name="mc_count" onChange={handleOnChange} size={'sm'} />
                        </FormControl>
                    </VStack>
                </SimpleGrid>

            </ChakraModal>

            <ChakraModal size={'sm'} primaryButtonText={'Print'} modalTitle={'Generate QR Codes'} isOpen={isQrGeneratorOpen} onClose={setQrGeneratorOpen}>

                {/* <FormControl isInvalid={form['mcCount'] > +selectedBatch?.mc_count}>
                    <FormLabel>MC Count</FormLabel>
                    <Input required name="mcCount" placeholder={`Max MC Count ${selectedBatch?.mc_count ?? ''}`} onChange={handleOnChange} size={'sm'} />
                    {form['mcCount'] > +selectedBatch?.mc_count && <FormErrorMessage mt={0}>MC count exceeded maximum </FormErrorMessage>}
                </FormControl> */}

                <Box mt={4}>
                    <QRCode
                        style={{ margin: 'auto' }}
                        value={JSON.stringify({
                            docEntry: id,
                            itemCode: _.get(line, 'item_code'),
                            batch: selectedBatch?.batch_number ?? ''
                        })}
                    />
                </Box>

            </ChakraModal>

            <PageHeader
                title={_.get(line, 'item_description')}
                subTitle={`Line Number: ${_.get(line, 'line_num')}`}
                isActionDisabled={getRemainingQty(line) === 0}
                onPress={() => {
                    clearAllFields()
                    setIsAddOpen(true)
                }}
                buttonText={"Create Batch"} />

            <Box pb={5} w={'50%'}>
                <VStack spacing={2} alignItems={'start'} p={4} borderRadius={6} bg={'white'}>
                    <Heading size={"sm"}>Item Details</Heading>
                    <Box w={'full'}>
                        <Flex w={'full'}>
                            <Text fontSize={13} flex={0.5}>Item Code</Text>
                            <Text fontSize={13} flex={0.5}>{_.get(line, 'item_code')}</Text>
                        </Flex>

                        <Flex w={'full'}>
                            <Text fontSize={13} flex={0.5}>Item Name</Text>
                            <Text fontSize={13} flex={0.5}>{_.get(line, 'item_description')}</Text>
                        </Flex>

                        <Flex w={'full'}>
                            <Text fontSize={13} flex={0.5}>Ordered Quantity</Text>
                            <Text fontSize={13} flex={0.5}>{_.get(line, 'quantity')}</Text>
                        </Flex>
                    </Box>
                </VStack>
            </Box>

            <ChakraTable title={'Batch Breakdown'} onSelected={(line: any) => {
            }} columns={COLUMN} data={_.get(line, 'batch_breakdown') ?? []} />
        </>
    )
}