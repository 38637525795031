import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

interface IProps {
    title?: string,
    subTitle?: string,
    buttonText?: string,
    onPress?: any,
    primaryButton?: boolean,
    isActionDisabled?: boolean
}

const PageHeader = ({
    title, isActionDisabled = false, subTitle, buttonText = "Button", primaryButton = true, onPress = () => {
    }
}: IProps) => {

    return (
        <Box px={0} pb={5} mx="auto">
            <Stack
                spacing="5"
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                align={{ base: 'flex-start', md: 'center' }}
            >
                <Stack>
                    <Heading size="md">{title}</Heading>
                    <Text color={'gray.400'} fontSize="sm">
                        {subTitle}
                    </Text>
                </Stack>

                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing="4"
                    justify="flex-end"
                    flex="1"
                    w={{ base: 'full', md: 'auto' }}
                >
                    {primaryButton && <Stack display="inline-flex" direction="row" spacing="4">
                        <Button isDisabled={isActionDisabled} size={'sm'} onClick={onPress} flexShrink={0} colorScheme="blue" fontWeight="bold">
                            {buttonText}
                        </Button>
                    </Stack>}
                </Stack>
            </Stack>
        </Box>
    )
}

export default PageHeader