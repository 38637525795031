import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Image,
    useBreakpointValue,
    useColorModeValue,
    VStack,
    useToast
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from '../../services/erp-users.service';
import logo from '../../consts/images/dlad-logo.png'
import useInput from '../../hooks/use-input.hook';

const LoginPage = () => {

    const toast = useToast()
    const navigate = useNavigate()
    const [login, { isLoading, isError }] = useLoginMutation()
    const [form, handleOnChange, handleOnChangeForValue, clearAllFields] = useInput();

    const loginHandler = async () => {
        try {
            let res: any = await login({
                ...form
            }).unwrap();

            if (res?.isLoggedIn) {
                navigate('/app', { replace: true })
            } else {
                toast({
                    title: 'Something went wrong!',
                    description: "Please chek your credentials",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
            }
        } catch (error: any) {
            toast({
                title: 'Something went wrong!',
                description: "Please chek your credentials",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }
    }

    return (
        <VStack className={'pattern'} h={'100vh'} justifyContent={"center"}>
            <Stack spacing="6">
                <VStack spacing={{ base: '2', md: '3' }} justifyContent="center" textAlign="center">
                    <Image
                        boxSize='100px'
                        objectFit='cover'
                        src={logo}
                        alt='Dan Abramov'
                    />
                    <Heading size="md">
                        DLAD Supplier Connect
                    </Heading>
                    <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                        Log in to your account
                    </Heading>
                </VStack>
            </Stack>
            <Box
                w={'400px'}
                py={{ base: '0', sm: '8' }}
                px={{ base: '4', sm: '10' }}
                bg={"white"}
                boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                borderRadius={{ base: 'none', sm: 'xl' }}
            >
                <form>
                    <Stack spacing="6">
                        <Stack spacing="5">

                            <FormControl>
                                <FormLabel htmlFor="email">Username</FormLabel>
                                <Input name="usr" type="text" onChange={handleOnChange} />
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="email">Password</FormLabel>
                                <Input name="pwd" type="password" onChange={handleOnChange} />
                            </FormControl>


                        </Stack>
                        <Stack spacing="6" mt={8}>
                            <Button isLoading={isLoading} onClick={loginHandler} colorScheme='blue'>Sign
                                in</Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </VStack>
    )
}

export default LoginPage