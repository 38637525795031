import { Box, Flex, FormControl, FormLabel, Heading, HStack, Input, SimpleGrid, Text, useToast, VStack } from "@chakra-ui/react";
import _ from 'lodash';
import { useRef, useState } from "react";
import { IoMdCreate } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import ChakraModal from "../../components/chakra-modal/ChakraModal";
import ChakraTable from "../../components/chakra-table/ChakraTable";
import PageHeader from "../../components/page-header/PageHeader";
import useInput from "../../hooks/use-input.hook";
import { useCreateMutation, useDeleteMutation, useListQuery, useUpdateMutation } from "../../services/erp-resource-api.service";

export const POViewPage = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const ref: any = useRef<any>(null)
    const toast = useToast()
    const [isAddPallet, setIsAddPallet] = useState<any>(false)
    const [isUpdate, setUpdate] = useState<any>(false)
    const [isDeletePallet, setIsDeletePallet] = useState<any>(false)
    let [form, handleOnChange, handleOnChangeForValue, clearAllFields, setFormData, st] = useInput();

    const { data: orderLines } = useListQuery({
        method: 'JB Purchase Order Line',
        filters: JSON.stringify([['doc_entry', '=', id]]),
        fields: ['name', 'item_code', 'item_description', 'line_num', 'quantity']
    })

    const { data: palletBreakdowns } = useListQuery({
        method: 'JB Pallet',
        filters: JSON.stringify([['doc_entry', '=', id]]),
        fields: ['name']
    })

    const { data: po, refetch } = useListQuery({
        method: 'JB Purchase Order',
        filters: JSON.stringify([['doc_entry', '=', id]]),
        fields: ['*']
    })

    let poData = po?.data?.[0];

    const [createPallet, result] = useCreateMutation();
    const [deletePallet, { isLoading }] = useDeleteMutation()
    const [update, { isLoading: isUpdating }] = useUpdateMutation()

    const createNewPallet = async () => {
        const res = await createPallet({
            doc: 'JB Pallet',
            payload: {
                doc_entry: id
            }
        })
        setIsAddPallet(false)
    }

    const deletePalletHandler = async (name: any) => {
        setIsDeletePallet(true)
        const res = await deletePallet({
            doc: 'JB Pallet',
            name
        }).unwrap()

        if (res?.message === 'ok') {
            toast({
                title: 'Success!',
                description: `${name} pallet deleted successfully`,
                status: 'success',
                duration: 2000,
                isClosable: true,
            })
        } else {
            toast({
                title: 'Error!',
                description: "Something went wrong!",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }

    }

    const onUpdate = (line: any) => {
        setFormData({})
        ref.current = { ...line }
        handleOnChangeForValue(line.id, line.value)
        setUpdate(true)
    }

    const onUpdateClickHandler = async () => {
        const res = await update({
            doc: 'JB Purchase Order',
            name: _.get(poData, 'name'),
            payload: { ...form }
        })
        ref.current = {}
        setUpdate(false)
        refetch()
    }

    const ORDER_LINE_T_COLUMNS = [
        {
            header: "Line Number",
            accessor: "line_num",
        },
        {
            header: "Item Code",
            accessor: "item_code",
        },
        {
            header: "Item Name",
            accessor: "item_description"
        },
        {
            header: "Quantity",
            accessor: "quantity"
        }
    ]

    const PALLET_LINE_T_COLUMNS = [
        {
            header: "Pallet Id",
            accessor: "name",
        },
        {
            header: "Actions",
            options: [
                {
                    label: "Delete",
                    action: (rowLine: any) => deletePalletHandler(rowLine.name)
                }
            ]
        }
    ]

    return (
        <>
            <PageHeader title={"Purchase Order"} subTitle={`OrderId: ${id}`} primaryButton={false} />

            <ChakraModal modalTitle={"Action Required!"} primaryAction={createNewPallet} isOpen={isAddPallet} onClose={setIsAddPallet}>
                <Text>{`Do you want to Create a pallet?`}</Text>
            </ChakraModal>

            <ChakraModal isLoading={isUpdating} primaryButtonText="Update" modalTitle={`Update ${ref?.current?.label}`} primaryAction={onUpdateClickHandler} isOpen={isUpdate} onClose={setUpdate}>
                <Box>
                    <FormControl>
                        <FormLabel>{ref?.current?.label}</FormLabel>
                        <Input onChange={handleOnChange} defaultValue={ref?.current?.value} required name={ref?.current?.id} type={'date'} size={'sm'} />
                    </FormControl>
                </Box>
            </ChakraModal>

            <SimpleGrid columns={2} spacing={'4'}>
                <Box pb={5}>
                    <VStack spacing={2} alignItems={'start'} p={4} borderRadius={7} bg={'white'}>
                        <Heading size={"sm"}>Basic Information</Heading>
                        <Box w={'full'}>
                            <Flex w={'full'}>
                                <Text fontSize={13} flex={0.5}>PO Number</Text>
                                <Text fontSize={13} flex={0.5}>{id}</Text>
                            </Flex>

                            <Flex w={'full'}>
                                <Text fontSize={13} flex={0.5}>PO Due Date</Text>
                                <Text fontSize={13} flex={0.5}>####-##-##</Text>
                            </Flex>

                            <Flex w={'full'}>
                                <Text fontSize={13} flex={0.5}>Shipping Instructions</Text>
                                <Text fontSize={13} flex={0.5}>####-##-##</Text>
                            </Flex>
                        </Box>
                    </VStack>
                </Box>
            </SimpleGrid>

            <SimpleGrid columns={2} spacing={'4'}>
                <Box pb={5}>
                    <VStack spacing={2} alignItems={'start'} p={4} borderRadius={7} bg={'white'}>
                        <Heading size={"sm"}>JB Shipping Information</Heading>
                        <Box w={'full'}>
                            <TupleLine isEditing={true} label="PO Draft Date" id="po_draft_date" value={_.get(poData, 'po_draft_date') ?? ''} onClick={onUpdate} />

                            <TupleLine isEditing={true} label="Pallet Calculation Received Date" id="pallet_calculation_received_date" value={_.get(poData, 'pallet_calculation_received_date') ?? ''} onClick={onUpdate} />

                            <TupleLine isEditing={true} label="PO Finalize Date" id="po_finalize_date" value={_.get(poData, 'po_finalize_date') ?? ''} onClick={onUpdate} />

                            <TupleLine isEditing={true} label="GRD Confirmation Date" id="grd_confirmation_date" value={_.get(poData, 'grd_confirmation_date') ?? ''} onClick={onUpdate} />

                            <TupleLine isEditing={true} label="PO Confirmation Date" id="po_confirmation_date" value={_.get(poData, 'po_confirmation_date') ?? ''} onClick={onUpdate} />

                            <TupleLine isEditing={true} label="SC Received Date" id="sc_received_date" value={_.get(poData, 'sc_received_date') ?? ''} onClick={onUpdate} />

                            <TupleLine isEditing={true} label="Sign Contract Sent Date" id="sign_contract_sent_date" value={_.get(poData, 'sign_contract_sent_date') ?? ''} onClick={onUpdate} />

                            <TupleLine isEditing={true} label="Shipment Advice Received Date" id="shipment_advice_received_date" value={_.get(poData, 'shipment_advice_received_date') ?? ''} onClick={onUpdate} />

                            <TupleLine isEditing={true} label="CA & Shipment Documents Sent Date" id="shipment_documents_sent_date" value={_.get(poData, 'shipment_documents_sent_date') ?? ''} onClick={onUpdate} />
                        </Box>
                    </VStack>
                </Box>

                <Box pb={5}>
                    <VStack spacing={2} alignItems={'start'} p={4} borderRadius={7} bg={'white'}>
                        <Heading size={"sm"}>Teadrop Estimations</Heading>
                        <Box w={'full'}>
                            <TupleLine label="PO Draft Date" id="u_po_draft_date" value={_.get(poData, 'u_po_draft_date') ?? ''} onClick={onUpdate} />

                            <TupleLine label="Pallet Calculation Received Date" id="u_pallet_calculation_received_date" value={_.get(poData, 'u_pallet_calculation_received_date') ?? ''} onClick={onUpdate} />

                            <TupleLine label="PO Finalize Date" id="u_po_finalize_date" value={_.get(poData, 'u_po_finalize_date') ?? ''} onClick={onUpdate} />

                            <TupleLine label="GRD Confirmation Date" id="u_grd_confirmation_date" value={_.get(poData, 'u_grd_confirmation_date') ?? ''} onClick={onUpdate} />

                            <TupleLine label="PO Confirmation Date" id="u_po_confirmation_date" value={_.get(poData, 'u_po_confirmation_date') ?? ''} onClick={onUpdate} />

                            <TupleLine label="SC Received Date" id="u_sc_received_date" value={_.get(poData, 'u_sc_received_date') ?? ''} onClick={onUpdate} />

                            <TupleLine label="Sign Contract Sent Date" id="u_sign_contract_sent_date" value={_.get(poData, 'u_sign_contract_sent_date') ?? ''} onClick={onUpdate} />

                            <TupleLine label="Shipment Advice Received Date" id="u_shipment_advice_received_date" value={_.get(poData, 'u_shipment_advice_received_date') ?? ''} onClick={onUpdate} />

                            <TupleLine label="CA & Shipment Documents Sent Date" id="u_shipment_documents_sent_date" value={_.get(poData, 'u_shipment_documents_sent_date') ?? ''} onClick={onUpdate} />
                        </Box>
                    </VStack>
                </Box>
            </SimpleGrid>

            <ChakraTable
                title={'Order Lines'}
                onSelected={(line: any) => navigate(`lines/${line.name}`)}
                columns={ORDER_LINE_T_COLUMNS}
                data={_.get(orderLines, 'data') ?? []} />

            <Box py={4}>
                <ChakraTable
                    primaryAction={() => setIsAddPallet(true)}
                    title={'Pallet Breakdown'}
                    onSelected={(line: any) => navigate(`pallets/${line.name}`)}
                    columns={PALLET_LINE_T_COLUMNS} data={_.get(palletBreakdowns, 'data') ?? []} />
            </Box>
        </>
    )
}

const TupleLine = ({ label, id, value, onClick, isEditing = false }: any) => {
    const [show, setShow] = useState<any>(false)

    const onClickHandler = (event: any) => {
        onClick({ id, value, label })
    }

    return (
        <Flex>
            <Text fontSize={13} flex={0.5}>{label}</Text>
            <HStack flex={0.2} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                {!isEditing && <Text fontSize={13}>{value}</Text>}
                {isEditing && <Text fontSize={13} onClick={onClickHandler}>{value}</Text>}
                {show && isEditing && <IoMdCreate onClick={onClickHandler} />}
            </HStack>
        </Flex>
    )
}
