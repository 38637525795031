import { useCallback, useReducer } from "react";

const useInput = () => {
    const [form, setFormData] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {})

    const handleOnChange = useCallback((event: any) => {
        const { name, value } = event.target;
        setFormData({ [name]: value })
    }, [])

    const handleOnChangeForValue = useCallback((key: any, value: any) => {
        setFormData({ [key]: value })
    }, [])

    //Clear all fields at once
    const clearAllFields = () => {
        let _form = {}
        for (let key in form) _form = { ..._form, [key]: '' }
        setFormData(_form)
    }

    //Clear one by one field
    const clearList = useCallback((...rest: any) => {
        let _form = {}
        for (let key of rest) _form = { ..._form, [key]: '' }
        setFormData({ ...form, ..._form })
    }, [])

    return [form, handleOnChange, handleOnChangeForValue, clearAllFields, setFormData, clearList]
}

export default useInput