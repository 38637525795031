import {useRoutes} from "react-router-dom";
import ExternalLayout from "../layouts/external/ExternalLayout";
import LoginPage from "../pages/login/LoginPage";
import HomePage from "../pages/home/HomePage";
import InternalLayout from "../layouts/internal/InternalLayout";
import {POViewPage} from "../pages/po-view/POViewPage";
import {ItemViewPage} from "../pages/items/ItemViewPage";
import {PalletViewPage} from "../pages/pallet-view/PalletViewPage";


const RouterConfigs = () => {
    const element: any = useRoutes([
        {
            path: "/",
            element: <ExternalLayout/>,
            children: [
                {
                    index: true,
                    element: <LoginPage/>
                },
            ]
        },
        {
            path: "/app",
            element: <InternalLayout/>,
            children: [
                {
                    index: true,
                    element: <HomePage/>
                },
                {
                    path: 'po/:id',
                    element: <POViewPage/>
                },
                {
                    path: 'po/:id/lines/:name',
                    element: <ItemViewPage/>
                },
                {
                    path: 'po/:id/pallets/:palletId',
                    element: <PalletViewPage />
                },
            ]
        },

        {path: "*", element: <h1>page not found</h1>},
    ]);

    return element;
};

export default RouterConfigs;